<template>
  <v-navigation-drawer
    v-if="data"
    :key="`${data.id}:${data.name}`"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : 362"
    app
    right
    temporary
    class="elevation-8"
  >
    <v-card-title>
      <div class="text-h6 py-1">
        <v-icon left>
          fal fa-books-medical
        </v-icon>
        {{ data?.id ? 'Edit' : 'Add' }} Reference
      </div>
      <v-spacer />
      <v-btn
        icon
        tile
        right
        color="grey"
        @click.stop="$emit('close-drawer')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-container class="spy-0">
      <reference-form
        :key="data.id"
        ref="referenceForm"
        :reference="data"
        info
        @loading="loading = $event"
        @reference-created="() => {
          $root.$emit('fetch-references')
          $emit('close-drawer')
          loading = false
        }"
      />
    </v-container>
    <template
      #append
    >
      <v-divider />
      <v-col>
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="$refs.referenceForm.createReference()"
        >
          {{ data?.id ? 'Update' : 'Add' }} Reference
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>
  export default {
    components: {
      ReferenceForm: () => import('@/components/reference/ReferenceForm.vue'),
    },
    props: {
      open: Boolean,
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        reference: this.data,
        valid: false,
        loading: false,
      }
    },
  }
</script>
